export const NHSRCashaDefaultChipsList = [
    {
        "id": "1",
        "value": {
            "en": "Pregnancy",
            "hi": "गर्भावस्था",
        },
        "img": "/Assets/Images/pregnancy.svg",
    },
    {
        "id": "2",
        "value": {
            "en": "Tuberculosis",
            "hi": "टीबी",

        },
        "img": "/Assets/Images/tuberculosis.svg",
    },
    {
        "id": "3",
        "value": {
            "en": "Child Health",
            "hi": "बाल स्वास्थ्य",
        },
        "img": "/Assets/Images/NewbornNutrition.svg",
    },
    {
        "id": "4",
        "value": {
            "en": "Immunisation",
            "hi": "टीकाकरण",
        },
        "img": "/Assets/Images/vaccination.svg",
    }
]

export const WCDawwDefaultChipsList = [
    {
        "id": "1",
        "value": {
            "en": "SAM/Wasting",
            "hi": "गंभीर कुपोषण",
        },
        "img": null
    },
    {
        "id": "2",
        "value": {
            "en": "Stunting",
            "hi": "नाटेपन",

        },
        "img": null
    },
    {
        "id": "3",
        "value": {
            "en": "Saksham Anganwadi",
            "hi": "सक्षम आंगनवाड़ी",
        },
        "img": null
    },
    {
        "id": "4",
        "value": {
            "en": "Poshan 2.0",
            "hi": "पोषण 2.0",
        },
        "img": null
    },
]

export const CTDashaDefaultChipsList = [
    {
        "id": "1",
        "value": {"en": "100 Days Campaign", "hi": "100 दिन अभियान"},
        "img": null,
    },
    {
        "id": "2",
        "value": {"en": "Support to TB Patients", "hi": "टीबी मरीजों को समर्थन"},
        "img": null,
    },
    {
        "id": "3",
        "value": {"en": "Schemes & Incentives", "hi": "योजनाएं और इंसेंटिव"},
        "img": null,
    },
    {
        "id": "4",
        "value": {"en": "About TB", "hi": "टीबी के बारे में"},
        "img": null,
    }
]

export const CTDchoDefaultChipsList = [
    {
        "id": "1",
        "value": {"en": "100 Days Campaign", "hi": "100 दिन अभियान"},
        "img": null,
    },
    {
        "id": "2",
        "value": {"en": "Support to TB Patients", "hi": "टीबी मरीजों को समर्थन"},
        "img": null,
    },
    {
        "id": "3",
        "value": {"en": "Patient Flow", "hi": "मरीजों की प्रक्रिया"},
        "img": null,
    },
    {
        "id": "4",
        "value": {"en": "Nikshay", "hi": "निक्षय"},
        "img": null,
    }
]